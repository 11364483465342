import React, { useState } from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import AbstractMedia from "../components/abstractMedia/AbstractMedia"
import arrow from "../images/arrows/article-arrow.png"
import Blocks from "./blocks/Blocks"

const ArticleList = ({ pageContext, location }) => {
  const [categorySelected, setCategorySelected] = useState(
    pageContext.articleCategories[0]
  )

  let page = null
  if (pageContext.page?.length > 0) {
    page = pageContext.page[0]
  }
  const lang = page?.locale ? page.locale.toLowerCase() : null

  const changeCategory = categorie => {
    setCategorySelected(categorie)
  }

  return (
    <Layout location={location}>
      <SEO
        titleSEO={page?.titleSEO || "Blog"}
        description={page?.description}
        title={page?.titleSEO}
      />
      {page?.blocks && (
        <Blocks blocks={page.blocks} media={pageContext.media} lang={lang} />
      )}
      <div className="frame">
        <div className="article-list container">
          <div className="article-category-button-list">
            {pageContext.articleCategories.map(category => (
              <button
                key={category.libelle}
                className={`article-category-button${
                  category.libelle === categorySelected.libelle
                    ? " selected"
                    : ""
                }`}
                onClick={() => changeCategory(category)}
              >
                {category.name}
              </button>
            ))}
          </div>
          {categorySelected?.articles.map(article => {
            const splittedDate = article.date
              ? article.date.split("-")
              : ["", "", ""]
            return (
              <div key={article.id} className="article-list-item">
                <div className="row">
                  <div className="col-md-auto">
                    <div className="article-list-item-left">
                      <a href={`/${lang}/blog/${article.url}`}>
                        <div className="article-list-date">
                          <span className="day">{splittedDate[2]}</span>
                          <span className="month">{splittedDate[1]}</span>
                          <span className="year">{splittedDate[0]}</span>
                        </div>
                        <div className="article-list-image">
                          {article.thumbnailImage && (
                            <AbstractMedia
                              media={pageContext.media}
                              abstractMedia={article.thumbnailImage}
                              title={article.thumbnailTitle}
                              className="img-fluid"
                            />
                          )}
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12">
                    <div className="article-list-text">
                      <span className="article-list-category">
                        {article.categoryName}
                      </span>
                      <span className="article-list-title">
                        {article.thumbnailTitle}
                      </span>
                      <span
                        className="article-list-description content"
                        dangerouslySetInnerHTML={{
                          __html: article.thumbnailDescription,
                        }}
                      />
                      <a href={`/${lang}/blog/${article.url}`}>
                        <img src={arrow} alt="flêche" />
                        <span>
                          {lang === "fr" ? "LIRE LA SUITE" : "READ MORE"}
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default ArticleList
